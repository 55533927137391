<template>
  <n-form>
    <p class="text-xl text-center mb-10">{{ $t('log_in_reset_pwd') }}</p>
    <nd-form-item :label="$t('log_in_pwd_title')" v-bind="password">
      <nd-input v-bind="password" />
    </nd-form-item>
    <nd-form-item :label="$t('member_auth_varify_password')" class="mt-5" v-bind="confirmPassword">
      <nd-input v-bind="confirmPassword" />
    </nd-form-item>
    <password-strength-bar class="mt-2 mb-5" :password="passwordString" />
    <nd-alert v-if="errorMessage" type="error" icon="alert" class="mb-2">
      {{ errorMessage }}
    </nd-alert>
    <nd-button class="mt-2 mb-5" block type="primary" :disabled="isSubmitDisabled" :loading="isSubmitting" @click="onSubmit">
      {{ $t('log_in_reset_pwd') }}
    </nd-button>
  </n-form>
</template>
<script setup lang="ts">
import { NForm } from 'naive-ui'
import { object as yupObject } from 'yup'

import PasswordStrengthBar from '@/components/login/password-strength-bar.vue'
import NdFormItem from '@/components/form/nd-form-item.vue'
import NdInput from '@/components/form/nd-input.vue'
import NdAlert from '@/components/common/nd-alert.vue'

import { createNFormItemConfig } from '@/utils/form'
import { useAuthStore } from '@/store/auth'
import { useAlertStore } from '@/store/alert'
import { resetPassword } from '@/service/login'

const { t } = useI18n()
const rules = useFieldRules()
const { getPasswordAttrs, confirmPasswordAttrs } = useFormAttrs()

const route = useRoute()
const router = useLocaleRouter()
const authStore = useAuthStore()
const alertStore = useAlertStore()

const isSubmitting = ref(false)

const validationSchema = yupObject({
  password: rules.password(),
  confirmPassword: rules.confirmPassword(),
})

const { defineComponentBinds, handleSubmit, meta, values, resetForm } = useForm({
  validationSchema: validationSchema,
})

const password = defineComponentBinds('password', createNFormItemConfig(getPasswordAttrs()))
const confirmPassword = defineComponentBinds('confirmPassword', createNFormItemConfig(confirmPasswordAttrs.value))

const token = computed(() => {
  return route.query.token as string
})

const isSubmitDisabled = computed(() => {
  return !meta.value.valid || !meta.value.dirty
})

const passwordString = computed(() => {
  return values.password
})

const errorMessage = ref('')
const onSubmit = handleSubmit(async values => {
  try {
    isSubmitting.value = true
    const { password } = values

    await resetPassword({ password, token: token.value })
    router.push({ path: '/' })
    resetForm()
    authStore.closeLoginModal()
    alertStore.success(t('member_password_reset_succeed'))
  } catch (err) {
    errorMessage.value = t('all_error_msg_try_again')
  } finally {
    isSubmitting.value = false
  }
})
</script>
