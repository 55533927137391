<template>
  <n-form>
    <p class="text-xl text-center mb-10">{{ $t('log_in_title') }}</p>
    <nd-form-item :label="$t('log_in_account_title')" v-bind="email">
      <nd-input v-bind="email" />
    </nd-form-item>
    <nd-form-item class="mt-4" :label="$t('log_in_pwd_title')" v-bind="password">
      <nd-input v-bind="password" />
    </nd-form-item>
    <div class="text-end mt-2 mb-5">
      <span class="cursor-pointer" @click="authStore.changeAuthStep('forgot-password')">
        {{ $t('log_in_forget_pwd') }}
      </span>
    </div>
    <nd-alert v-if="errorMessage" type="error" icon="alert">
      {{ errorMessage }}
    </nd-alert>
    <nd-button class="mt-2" block type="primary" :disabled="isSubmitDisabled" @click="onSubmit">{{ $t('log_in_title') }}</nd-button>
    <div class="text-center mt-5">
      <trans i18n-key="log_in_create_account" :components="transComponents" />
    </div>
    <hr class="my-4" />
    <div class="text-center color-yellow-50 cursor-pointer" @click="authStore.changeAuthStep('login-menu')">{{ $t('log_in_other_methods') }}</div>
  </n-form>
</template>
<script setup lang="ts">
import { FetchError } from 'ofetch'
import { NForm } from 'naive-ui'
import { object as yupObject } from 'yup'

import { useAuthStore } from '@/store/auth'

import { useLogin } from '@/composables/useAuth'

import NdFormItem from '@/components/form/nd-form-item.vue'
import NdInput from '@/components/form/nd-input.vue'
import NdAlert from '@/components/common/nd-alert.vue'

import { login } from '@/service/login'

import { createNFormItemConfig } from '@/utils/form'

import { ReCaptchaAction } from '@/types/recaptcha'
import { ResponseCode } from '@/types/service'

const { t } = useI18n()
const rules = useFieldRules()

const authStore = useAuthStore()

const validationSchema = yupObject({
  email: rules.email(),
  password: rules.password(),
})

const { handleLoginSuccess } = useLogin()
const { emailAttrs, getPasswordAttrs } = useFormAttrs()
const { defineComponentBinds, handleSubmit, meta, resetForm } = useForm({
  validationSchema: validationSchema,
})

const email = defineComponentBinds('email', createNFormItemConfig(emailAttrs.value))
const password = defineComponentBinds('password', createNFormItemConfig(getPasswordAttrs()))

const isSubmitDisabled = computed(() => {
  return !meta.value.valid || !meta.value.dirty
})
const transComponents = computed(() => ({
  CrateAccount: {
    tag: 'span',
    props: { class: 'inline-block ml-2 color-yellow-50 cursor-pointer', onClick: () => authStore.changeAuthStep('sign-up') },
  },
}))

const errorMessage = ref('')
const { execute } = useRecaptcha()
const onSubmit = handleSubmit(async values => {
  try {
    const token = await execute(ReCaptchaAction.LOGIN)
    const { email, password } = values
    await login({ email, password, verification_token: token })
    resetForm()
    await handleLoginSuccess()
  } catch (err) {
    if (err instanceof FetchError) {
      switch (err.data.code) {
        case ResponseCode.WRONG_ACCOUNT_OR_PASSWORD:
          errorMessage.value = t('member_auth_error_msg_email')
          break
        default:
          errorMessage.value = t('all_error_msg_try_again')
          break
      }
    }
  }
})
</script>
<style lang="scss"></style>
