<template>
  <nd-modal
    v-model:show="isLoginModalShown"
    card-class="w-full h-full md:w-440px md:pb-5 md:h-auto"
    :closable="isCloseButtonShown"
    :mask-closable="authStep !== 'reset-password'"
    @after-leave="handleModalLeave"
  >
    <div class="w-160px mx-auto mb-6">
      <nd-img src="@/assets/images/logo.svg" alt="Niceday Logo" class="text-yellow-50" />
    </div>
    <client-only>
      <div class="px-5 md:px-10">
        <component :is="AuthStepComponent" />
      </div>
    </client-only>
  </nd-modal>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import NdModal from '../common/nd-modal.vue'
import LoginMenu from './login-menu.vue'
import LoginStep from './login-step.vue'
import RegisterStep from './register-step.vue'
import ForgotPasswordStep from './forgot-password-step.vue'
import ResetPassword from './reset-password.vue'

import { useAuthStore } from '@/store/auth'
import { QUERY_STRING_KEYS } from '@/constants/query-string-keys'

const authStore = useAuthStore()
const route = useRoute()
const router = useLocaleRouter()

const { isLogin, authStep, isLoginModalShown } = storeToRefs(authStore)

const loginPendingAction = useLoginPendingActionCookie()

const isCloseButtonShown = computed(() => authStep.value !== 'reset-password')

const AuthStepComponent = computed(() => {
  switch (authStep.value) {
    case 'sign-up':
      return RegisterStep
    case 'login':
      return LoginStep
    case 'forgot-password':
      return ForgotPasswordStep
    case 'reset-password':
      return ResetPassword
    case 'login-menu':
    default:
      return LoginMenu
  }
})

const clearEffects = () => {
  if (!isLogin.value) return

  if (route.query[QUERY_STRING_KEYS.REDIRECT_URL]) {
    const copiedQuery = cloneDeep(route.query)

    delete copiedQuery[QUERY_STRING_KEYS.REDIRECT_URL]
    router.replace({ query: copiedQuery })
  }
  loginPendingAction.value = null
}

const handleModalLeave = () => {
  authStore.closeLoginModal()
  clearEffects()
}
</script>
