<template>
  <button class="btn w-full h-10 mb-3" @click="facebookLoginByRedirect">
    <div class="w-6 h-6 flex-0">
      <nd-img src="/images/facebook-circle.png" />
    </div>
    <span class="flex-0 break-keep">{{ $t('log_in_facebook') }}</span>
  </button>
</template>
<script setup lang="ts">
import { QUERY_STRING_KEYS } from '@/constants/query-string-keys'

const config = useRuntimeConfig()

function facebookLoginByRedirect() {
  const route = useRoute()
  const redirectTo = useCookie('RedirectTo', { path: '/', maxAge: 60 })
  redirectTo.value = JSON.stringify({ redirectUrl: route.query[QUERY_STRING_KEYS.REDIRECT_URL], fallback: window.location.pathname })

  const fbLoginUrl = `https://www.facebook.com/v17.0/dialog/oauth?client_id=${config.public.fbAppId}&redirect_uri=${window.location.origin}/auth/fb-login&scope=email&public_profile&response_type=token`
  location.href = fbLoginUrl
}
</script>

<style scoped>
.btn {
  @apply px-4 py-2 text-lg border rounded transition-colors cursor-pointer disabled:cursor-default flex justify-center items-center flex-nowrap gap-2;
}
</style>
