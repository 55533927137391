<template>
  <client-only>
    <GoogleLogin :callback="googleLoginCallback" popup-type="TOKEN">
      <nd-button class="w-full h-10" bordered icon="google-login" :disabled="false">{{ $t('log_in_google') }}</nd-button>
    </GoogleLogin>
    <p v-if="isWebview" class="mt-2 text-gray-80 text-xs">{{ $t('member_auth_google_preauth_reminder') }}</p>
  </client-only>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import type { CallbackTypes } from 'vue3-google-login'

import { useLogin } from '@/composables/useAuth'
import { useAppStore } from '@/store/app'
import { googleLogin, googleRegister } from '@/service/login'

import { ResponseCode } from '@/types/service'
import { GtmEvent } from '@/types/gtm'

const { handleLoginSuccess } = useLogin()
const { isWebview } = storeToRefs(useAppStore())

const emit = defineEmits<{
  (event: 'update-error-message', message: string): void
}>()

const googleLoginCallback: CallbackTypes.TokenResponseCallback = async response => {
  try {
    const res = await googleLogin(response.access_token)
    if (res.code === ResponseCode.SUCCESS) {
      useNuxtApp().$trackEvent({
        event: GtmEvent.ACCOUNT_LOGGED_IN,
        payload: {
          'Registration Type': 'Google',
        },
      })
    } else {
      if (res.code !== ResponseCode.UNREGISTERED) throw new Error('login failed')

      const registerRes = await googleRegister(response.access_token)
      if (registerRes.code !== ResponseCode.SUCCESS) throw new Error('register failed')

      useNuxtApp().$trackEvent({
        event: GtmEvent.ACCOUNT_SIGNED_UP,
        payload: {
          'Registration Type': 'Google',
        },
      })
    }
    await handleLoginSuccess()
  } catch (err) {
    if (err instanceof Error) {
      emit('update-error-message', err.message)
    }
  }
}
</script>

<style lang="scss">
.g-btn-wrapper {
  @apply w-full;
}
</style>
