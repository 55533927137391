<template>
  <n-form>
    <p class="text-xl text-center mb-10">{{ $t('log_in_register_title') }}</p>
    <nd-form-item :label="$t('log_in_account_title')" v-bind="email">
      <nd-input v-bind="email" />
    </nd-form-item>
    <nd-form-item class="mt-4" :label="$t('log_in_pwd_title')" v-bind="password">
      <nd-input v-bind="password" />
    </nd-form-item>
    <nd-form-item class="mt-2" :show-label="false" v-bind="confirmPassword">
      <nd-input v-bind="confirmPassword" />
    </nd-form-item>
    <password-strength-bar class="mt-2 mb-5" :password="passwordString" />
    <nd-alert v-if="errorMessage" type="error" icon="alert" class="mb-2">
      {{ errorMessage }}
    </nd-alert>
    <nd-button
      v-gtm:click="{ event: GtmEvent.ACCOUNT_SIGN_UP_CLICKED, payload: { 'Registration Type': 'Email' } }"
      class="mt-2 mb-5"
      block
      type="primary"
      :disabled="isSubmitDisabled"
      @click="onSubmit"
    >
      {{ $t('log_in_register_title') }}
    </nd-button>
    <div class="text-center">
      <trans i18n-key="register_already_member" :components="transComponents" />
    </div>
    <hr class="my-4" />
    <div class="text-center color-yellow-50 cursor-pointer" @click="authStore.changeAuthStep('login-menu')">{{ $t('log_in_other_methods') }}</div>
  </n-form>
</template>
<script setup lang="ts">
import { FetchError } from 'ofetch'
import { NForm } from 'naive-ui'
import { object as yupObject } from 'yup'

import { useLogin } from '@/composables/useAuth'

import NdAlert from '@/components/common/nd-alert.vue'
import NdFormItem from '@/components/form/nd-form-item.vue'
import NdInput from '@/components/form/nd-input.vue'
import PasswordStrengthBar from '@/components/login/password-strength-bar.vue'

import { useAlertStore } from '@/store/alert'
import { useAuthStore } from '@/store/auth'
import { register } from '@/service/login'

import { createNFormItemConfig } from '@/utils/form'

import { ReCaptchaAction } from '@/types/recaptcha'
import { ResponseCode } from '@/types/service'
import { GtmEvent } from '@/types/gtm'

const { t } = useI18n()
const rules = useFieldRules()
const { emailAttrs, confirmPasswordAttrs, getPasswordAttrs } = useFormAttrs()
const { handleLoginSuccess } = useLogin()

const authStore = useAuthStore()
const alertStore = useAlertStore()

const validationSchema = yupObject({
  email: rules.email(),
  password: rules.password(),
  confirmPassword: rules.confirmPassword(),
})

const { defineComponentBinds, handleSubmit, meta, values, resetForm } = useForm({
  validationSchema: validationSchema,
})

const email = defineComponentBinds('email', createNFormItemConfig(emailAttrs.value))
const password = defineComponentBinds('password', createNFormItemConfig(getPasswordAttrs()))
const confirmPassword = defineComponentBinds('confirmPassword', createNFormItemConfig(confirmPasswordAttrs.value))

const passwordString = computed(() => {
  return values.password
})
const isSubmitDisabled = computed(() => {
  return !meta.value.valid || !meta.value.dirty
})
const transComponents = computed(() => ({
  login: {
    tag: 'span',
    props: { class: 'inline-block ml-2 color-yellow-50 cursor-pointer', onClick: () => authStore.changeAuthStep('login') },
  },
}))

const errorMessage = ref('')
const { execute } = useRecaptcha()
const onSubmit = handleSubmit(async values => {
  try {
    const token = await execute(ReCaptchaAction.REGISTER)
    const { email, password } = values
    const registerRes = await register({ email, password, verification_token: token })
    if (registerRes.code !== 0) {
      throw registerRes
    }
    resetForm()
    await handleLoginSuccess()
    alertStore.success(t('member_auth_register_succeed'))
    useNuxtApp().$trackEvent({
      event: GtmEvent.ACCOUNT_SIGNED_UP,
      payload: {
        'Registration Type': 'Email',
      },
    })
  } catch (err) {
    if (err instanceof FetchError) {
      switch (err.data.code) {
        case ResponseCode.DUPLICATE_KEY:
          errorMessage.value = t('member_auth_error_msg_email_duplicated')
          break
        default:
          errorMessage.value = t('all_error_msg_try_again')
          break
      }
    }
  }
})
</script>
