<template>
  <p class="text-xl text-center mb-10">{{ $t('log_in_slogan_title') }}</p>
  <div class="mb-150px">
    <facebook-login />
    <div class="mb-3">
      <nd-google-login @update-error-message="updateErrorMessage" />
    </div>

    <nd-button class="w-full h-10" icon="mail" bordered @click="authStore.changeAuthStep('login')">{{ $t('log_in_email') }}</nd-button>
  </div>
  <nd-alert v-if="errorMessage" type="error" icon="alert" class="mb-2">
    {{ errorMessage }}
  </nd-alert>

  <p>
    <trans i18n-key="log_in_agreement_statement" :components="transComponents" />
  </p>
</template>

<script setup lang="ts">
import LocaleLink from '@/components/global/locale-link.vue'
import NdGoogleLogin from '@/components/login/nd-google-login.vue'
import FacebookLogin from '@/components/login/facebook-login.vue'
import NdAlert from '@/components/common/nd-alert.vue'
import { useAuthStore } from '@/store/auth'

const authStore = useAuthStore()

const errorMessage = ref('')
const transComponents = computed(() => ({
  terms: { tag: LocaleLink, props: { class: 'text-yellow-50', to: { path: '/policy' }, target: '_blank' } },
  policy: { tag: LocaleLink, props: { class: 'text-yellow-50', to: { path: '/privacy' }, target: '_blank' } },
}))

const updateErrorMessage = (message: string) => {
  errorMessage.value = message
}
</script>
