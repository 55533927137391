<template>
  <n-form>
    <p class="text-xl text-center mb-10">{{ $t('log_in_forget_pwd_title') }}</p>
    <nd-form-item :label="$t('log_in_account_title')" v-bind="email">
      <nd-input v-bind="email" />
    </nd-form-item>
    <ul class="list-disc color-gray-80 mt-2 mb-5 ml-5">
      <li>{{ $t('log_in_forget_pwd_hint') }}</li>
      <li>{{ $t('log_in_forget_pwd_hint_cs') }}</li>
    </ul>
    <nd-alert v-if="alertMessage" :type="alertType" icon="alert" :iconMargin="alertIconMargin" :bordered="false" class="mb-2">
      {{ alertMessage }}
    </nd-alert>
    <nd-button block type="primary" :disabled="isSubmitDisabled" @click="onSubmit">{{ $t('log_in_reset_pwd_send') }}</nd-button>
    <div class="w-full h-1px bg-gray-40 mt-9 my-4"></div>
    <div class="text-center color-yellow-50 cursor-pointer" @click="authStore.changeAuthStep('login-menu')">{{ $t('log_in_back_to_register') }}</div>
  </n-form>
</template>
<script setup lang="ts">
import { NForm } from 'naive-ui'
import { object as yupObject } from 'yup'

import { useAuthStore } from '@/store/auth'

import NdFormItem from '@/components/form/nd-form-item.vue'
import NdInput from '@/components/form/nd-input.vue'
import NdAlert, { type NDAlertType } from '@/components/common/nd-alert.vue'

import { forgotPassword } from '@/service/login'

import { createNFormItemConfig } from '@/utils/form'

import { ReCaptchaAction } from '@/types/recaptcha'

const { t } = useI18n()
const rules = useFieldRules()
const { emailAttrs } = useFormAttrs()

const authStore = useAuthStore()

const validationSchema = yupObject({
  email: rules.email(),
})

const { defineComponentBinds, handleSubmit, meta, resetForm } = useForm({
  validationSchema: validationSchema,
})

const email = defineComponentBinds('email', createNFormItemConfig(emailAttrs.value))

const isSubmitDisabled = computed(() => {
  return !meta.value.valid || !meta.value.dirty
})

const alertType = ref<NDAlertType>('error')
const alertMessage = ref('')
const alertIconMargin = computed(() => {
  return alertType.value === 'success' ? '28px 12px 0px 20px' : ''
})
const { execute } = useRecaptcha()
const onSubmit = handleSubmit(async values => {
  try {
    const token = await execute(ReCaptchaAction.PASSWORD_RECOVER)
    const { email } = values
    await forgotPassword({ email, verification_token: token })
    alertType.value = 'success'
    alertMessage.value = t('forget_pwd_reset_toast')
    resetForm()
  } catch (err) {
    alertMessage.value = t('all_error_msg_try_again')
  }
})
</script>
