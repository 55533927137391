<template>
  <render />
</template>

<script setup lang="ts">
import { Fragment } from 'vue'
import { type NamedValue } from 'vue-i18n'

type ComponentInfo = {
  tag: Parameters<typeof h>[0]
  props: Parameters<typeof h>[1]
}

interface Props {
  i18nKey: string
  /** NOTE: 請確保 `components` 中的 `tag` 是 unique，且 `tag` 中不包其他 `tag`，巢狀的目前不支援。 */
  components: Record<string, ComponentInfo>
  values?: Record<string, PropertyKey> | PropertyKey[]
}

const props = withDefaults(defineProps<Props>(), { values: () => ({}) })

const { t } = useI18n()
const findTagAndTextRegex = /<([^>]+)>([^<]+)<\/[^>]+>/g

const render = computed(() => {
  const result = []
  const i18nText = t(props.i18nKey, props.values as NamedValue)

  const splittedAry = i18nText.split(findTagAndTextRegex)

  for (let i = 0; i < splittedAry.length; i++) {
    if (splittedAry[i] in props.components) {
      const theComponent = props.components[splittedAry[i]]
      const isHTMLTag = typeof theComponent.tag === 'string'
      /**
       * for performance: custom component need to use function
       * ref: https://stackoverflow.com/questions/69875273/non-function-value-encountered-for-default-slot-in-vue-3-composition-api-comp
       */
      const children = isHTMLTag ? splittedAry[i + 1] : () => splittedAry[i]

      i = i + 1

      result.push(h(theComponent.tag, theComponent.props, children))
      continue
    }

    result.push(splittedAry[i])
  }

  return h(Fragment, null, result)
})
</script>
